<template>
  <div>
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="序号" >
            <a-input-number :min="1" v-decorator="['serial', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            <span style="margin-left: 30px"><span style="color: red">提示：</span>序号小的在前边</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="条形码">
            <a-input disabled v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="自定义条形码" >
            <a-switch v-decorator="['is_custom_barcode', {initialValue: false, valuePropName: 'checked'}]" checked-children="是" un-checked-children="否"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="货品名称">
            <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="英文名称" >
            <a-input v-decorator="['en_name', {rules: [{required: false, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="货品类型">
            <a-select
              showSearch
              allow-clear
              v-decorator="['goods_type', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
              placeholder="请选择类型"
              @change="goodsTypeChange"
              style="width: 100%"
            >
              <a-spin v-if="fetching" slot="notFoundContent" size="small" />
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.goods_type">
                {{ val }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="货品单位" >
            <a-select
              v-decorator="[
                'unit',
                {
                  rules: [
                    { required: goods_type===2, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="开启保质期" >
            <a-switch v-decorator="['open_self_life', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关" @change="openSelfLife"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="是否是套装" >
            <a-switch v-decorator="['is_union', {initialValue: false, valuePropName: 'checked'}]" checked-children="是" un-checked-children="否"/>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="open_self_life">
          <a-form-item label="保质期">
            <a-input-number v-decorator="['shelf_life', {initialValue: 1, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="open_self_life">
          <a-form-item label="保质期单位">
            <a-select
              v-decorator="[
                'shelf_life_unit',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              style="width: 90px"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.shelf_life_unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            label="所属分类">
            <a-cascader
              :options="categoryList"
              placeholder="请选择"
              v-decorator="['category', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
              @change="handleCategoryChange"
              :field-names="{ label: 'name', value: 'code', children: 'children' }" />
          </a-form-item>
        </a-col>
        <a-col :span="13">
          <a-form-item label="备注" >
            <a-textarea v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="false">
          <a-form-item label="法定单位" >
            <a-select
              v-decorator="[
                'legal_unit',
                {
                  rules: [
                    { required: goods_type===2, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="false">
          <a-form-item label="法定单位2" >
            <a-select
              v-decorator="[
                'legal_unit2',
                {
                  rules: [
                    { required: goods_type===2, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="false">
          <a-form-item label="原产地" >
            <a-select
              v-decorator="[
                'original_place',
                {
                  rules: [
                    { required: goods_type===2, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.country">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="false">
          <a-form-item label="关税税率" >
            <a-input
              addon-after="%"
              v-decorator="['tariff_rate', {initialValue: 0, rules: [{required: goods_type===2, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="false">
          <a-form-item label="增值税" >
            <a-input
              addon-after="%"
              v-decorator="['value_added_tax_rate', {initialValue: 0, rules: [{required: goods_type===2, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="false">
          <a-form-item label="开启消费税" >
            <a-switch v-decorator="['open_consumption_tax', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关" @change="openConsumptionTax"/>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="open_consumption_tax === 2">
          <a-form-item label="消费税单位">
            <a-select
              v-decorator="[
                'consumption_tax_unit',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.consumption_tax_unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="24" v-if="open_consumption_tax === 2">
          <div>
            <a-button icon="plus" @click="handleAddTaxLevel" type="primary" style="margin-bottom: 5px;">
              添加
            </a-button>
            <a-table bordered :data-source="tax_rate_info.tax_level_list" :columns="tax_level_columns" :pagination="false">
              <template
                v-for="col in ['min_price', 'max_price', 'consumption_tax_rate']"
                :slot="col"
                slot-scope="text, record"
              >
                <div :key="col">
                  <a-input-number
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    @change="e => handleTaxLevelChange(e, record.key, col)"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record">
                <div class="editable-row-operations">
                  <span v-if="record.editable">
                    <a @click="() => save(record.key)">保存</a>
                    <a-divider type="vertical" />
                    <a-popconfirm title="确定取消吗?" @confirm="() => cancel(record.key)">
                      <a>取消</a>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a :disabled="editingKey !== ''" @click="() => edit(record.key)">编辑</a>
                  </span>
                  <a-divider type="vertical" />
                  <a-popconfirm
                    v-if="tax_rate_info.tax_level_list.length"
                    title="确定删除吗?"
                    @confirm="() => onDelete(record.key)"
                  >
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
                </div>
              </template>
            </a-table>
          </div>
        </a-col>
        <a-col span="6" :offset="6">
          <a-button @click="handleGoBack">返回</a-button>
        </a-col>
        <a-col span="2" style="text-align: center">
          <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { goods_list_id, goods_update, goods_category_list } from '@/api/c_wms_goods'
// 表单字段
const fields = ['id', 'name', 'goods_type', 'en_name', 'barcode', 'is_custom_barcode', 'category', 'hs_code', 'unit', 'legal_unit', 'legal_unit2', 'open_self_life',
  'shelf_life', 'shelf_life_unit', 'tariff_rate', 'consumption_tax_rate', 'value_added_tax_rate', 'is_active', 'total_count', 'serial', 'remark',
  'open_consumption_tax', 'consumption_tax_unit', 'is_union'
]
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      id: 0,
      fetching: false,
      goods_type: 1,
      location: '',
      wms_goodss: [],
      categoryList: [],
      open_self_life: false,
      open_consumption_tax: 1,
      tax_rate_info: {
        open_consumption_tax: this.open_consumption_tax,
        consumption_tax_unit: this.consumption_tax_unit,
        tax_level_list: []
      },
      cacheData: [],
      editingKey: '',
      tax_level_columns: [
        {
          title: '最小值',
          dataIndex: 'min_price',
          width: 100,
          scopedSlots: { customRender: 'min_price' }
        },
        {
          title: '最大值',
          dataIndex: 'max_price',
          width: 100,
          scopedSlots: { customRender: 'max_price' }
        },
        {
          title: '比例',
          dataIndex: 'consumption_tax_rate',
          width: 100,
          scopedSlots: { customRender: 'consumption_tax_rate' }
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'operation',
          width: 100,
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  mounted () {
    this.goodsCategory()
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
      this.openSelfLife(this.record.open_self_life)
    })
  },
  methods: {

    openSelfLife (value) {
      this.open_self_life = !!value
    },
    goodsCategory () {
      goods_category_list().then(res => {
        const entries = res.data.entries
        const level1 = []
        const level2 = []
        const level3 = []
        entries.forEach((e, i) => {
          if (e.level === 1) {
            level1.push(e)
          } else if (e.level === 2) {
            level2.push(e)
          } else {
            level3.push(e)
          }
        })

        level1.forEach((e, i) => {
          e.children = []
          level2.forEach((e2, i2) => {
            e2.children = []
            if (e.code === e2.code.substr(0, 2)) {
              e.children.push(e2)
            }
            level3.forEach((e3, i3) => {
              if (e2.code === e3.code.substr(0, 4)) {
                e2.children.push(e3)
              }
            })
          })
        })
        this.categoryList = level1
      })
    },
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleCategoryChange (value) {
      if (value.length !== 3) {
        this.$message.warning('所属分类必须三级')
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    goodsTypeChange (value) {
      this.goods_type = Number(value)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
          return
        }
        console.log('--------------------', values)
        // values.tax_level_list = this.tax_rate_info.tax_level_list.map(item => {
        //   item.key = undefined
        //   item.editable = undefined
        //   return item
        // })
        // values.tariff_rate = (values.tariff_rate / 100).toFixed(2)
        // values.value_added_tax_rate = (values.value_added_tax_rate / 100).toFixed(2)
        // values.open_consumption_tax = values.open_consumption_tax ? 2 : 1
        if (values.category.length !== 3) {
          this.$message.warning('所属分类必须三级')
          return
        }
        values.category = values.category[2]
        goods_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
    })
      })
    },
    wmsWarehouseList () {
      goods_list_id().then(res => {
        this.wms_goodss = res.data
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const category = []
        category.push(data.category.substr(0, 2))
        category.push(data.category.substr(0, 4))
        category.push(data.category.substr(0, 6))
        data.goods_type = data.goods_type + ''
        data.category = category
        const formData = pick(data, fields)
        this.id = formData.id
        formData.shelf_life_unit = formData.shelf_life_unit + ''
        form.setFieldsValue(formData)
      })
    },
    openConsumptionTax (value) {
      this.open_consumption_tax = value ? 2 : 1
      console.log(this.open_consumption_tax)
    },
    handleAddTaxLevel () {
      this.tax_rate_info.tax_level_list.push({
        key: this.tax_rate_info.tax_level_list.length + 1,
        min_price: 0,
        max_price: 0,
        consumption_tax_rate: 0.00,
        editable: false
      })
      this.cacheData = this.tax_rate_info.tax_level_list
    },
    handleTaxLevelChange (value, key, column) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.tax_rate_info.tax_level_list = newData
      }
    },
    edit (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = key
      if (target) {
        target.editable = true
        this.tax_rate_info.tax_level_list = newData
      }
    },
    save (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const newCacheData = [...this.cacheData]
      const target = newData.find(item => key === item.key)
      const targetCache = newCacheData.find(item => key === item.key)
      if (target && targetCache) {
        delete target.editable
        this.tax_rate_info.tax_level_list = newData
        Object.assign(targetCache, target)
        this.cacheData = newCacheData
      }
      this.editingKey = ''
    },
    cancel (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.key))
        delete target.editable
        this.tax_rate_info.tax_level_list = newData
      }
    },
    onDelete (key) {
      const newData = [...this.tax_rate_info.tax_level_list]
      const target = newData.find(item => key === item.key)
      this.editingKey = ''
      if (target) {
        Object.assign(target, this.cacheData.find(item => key === item.key))
        delete target.editable
        this.tax_rate_info.tax_level_list = newData.filter(item => {
          if (item.key !== key) {
            return item
          }
        })
      }
    }
  }
}
</script>
