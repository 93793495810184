<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="货品名称">
              <a-input v-model="queryParam.name" placeholder="请输入名称" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="条形码">
              <a-input v-model="queryParam.barcode" placeholder="请输入完整的编码" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="货品类型">
              <a-select
                v-model="queryParam.goods_type"
                placeholder="选择类型"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                allow-clear
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.goods_type">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-form-item label="是否套盒">
              <a-select
                v-model="queryParam.is_union"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                allow-clear
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.is_union">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :scroll="{ x: 2000 }"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 150px; max-width: 150px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleWithGoods(record)" v-if="record.is_union">套盒商品</a>
          <a-divider type="vertical" v-if="record.is_union"/>
<!--          <a @click="handleGoodsDeclaration(record)">商品申报</a>-->
<!--          <a-divider type="vertical" />-->
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleCountRecord(record)">变动记录</a>
          <a-divider type="vertical" v-if="false" />
          <a-popconfirm
            v-if="false"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <goods-declaration-form
      ref="goodsDeclarationModal"
      v-if="declaration_visible"
      :visible.sync="declaration_visible"
      :loading="declaration_ConfirmLoading"
      :model="declaration_mdl"
      @cancel="handleDeclaration_Cancel"
      @ok="handleDeclarationOk"
    />
    <goods-inventory-record-form
      v-if="goods_inventory_record_visible"
      :visible.sync="goods_inventory_record_visible"
      :model="goods_inventory_record"
      @cancel="handleInventoryRecordCancel"
      @ok="handleInventoryRecordOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/goods/modules/CreateForm'
import GoodsDeclarationForm from '@/views/c-wms/goods/modules/GoodsDeclarationForm'
import GoodsInventoryRecordForm from '@/views/c-wms/goods/modules/GoodsInventoryRecordForm'
import { goods_create, goods_delete, goods_list } from '@/api/c_wms_goods'
import { Base64 } from 'js-base64'

const { goods_category_list } = require('@/api/c_wms_goods')

export default {
  name: 'TableList',
  components: {
    GoodsDeclarationForm,
    STable,
    CreateForm,
    GoodsInventoryRecordForm
  },
  data () {
    return {
      pageNum: 1,
      loading: false,
      confirmLoading: false,
      declaration_ConfirmLoading: false,
      current_bill_id: 0,
      categoryList: [],
      // 创建窗口控制
      visible: false,
      declaration_visible: false,
      goods_inventory_record_visible: false,
      mdl: {},
      declaration_mdl: {},
      goods_inventory_record: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'serial',
          width: 50,
          fixed: 'left',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          align: 'center',
          width: 200,
          dataIndex: 'name'
        },
        {
          title: '英文名称',
          align: 'center',
          width: 150,
          dataIndex: 'en_name',
          ellipsis: true
        },
        {
          title: '条形码',
          align: 'center',
          width: 200,
          dataIndex: 'barcode',
          ellipsis: true
        },
        {
          title: '是否是自定义条形码',
          align: 'center',
          width: 150,
          dataIndex: 'is_custom_barcode',
          customRender: text => text ? '是' : '否'
        },
        {
          title: '货品类型',
          align: 'center',
          ellipsis: true,
          width: 100,
          dataIndex: 'goods_type',
          customRender: text => this.$Dictionaries.goods_type[text] || '无'
        },
        {
          title: '货品分类',
          align: 'center',
          ellipsis: true,
          width: 120,
          dataIndex: 'category',
          customRender: (text, record) => {
            const titles = []
            this.categoryList.forEach(item => {
              if (item.code === text.substr(0, 2)) {
                titles[0] = item.name
              } else if (item.code === text.substr(0, 4)) {
                titles[1] = item.name
              } else if (item.code === text) {
                titles[2] = item.name
              }
            })
            return titles.toString()
          }
        },
        {
          title: '开启保质期',
          align: 'center',
          width: 100,
          dataIndex: 'open_self_life',
          customRender: (text) => text === true ? '是' : '否'
        },
        {
          title: '保质期',
          align: 'center',
          width: 100,
          customRender: (text, record) => {
            const time = `${record.shelf_life} ${this.$Dictionaries.shelf_life_unit[record.shelf_life_unit]}` || '无'
            return time
          }
        },
        {
          title: '总量',
          align: 'center',
          width: 150,
          dataIndex: 'total_count',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '单位',
          align: 'center',
          width: 100,
          dataIndex: 'unit',
          customRender: text => this.$Dictionaries.unit[text] || '无'
        },
        {
          title: '套盒',
          align: 'center',
          width: 100,
          dataIndex: 'is_union',
          customRender: text => text ? '是' : '否'
        },
        {
          title: '备注',
          align: 'center',
          ellipsis: true,
          dataIndex: 'remark'
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          dataIndex: 'action',
          width: 220,
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return goods_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
    this.goodsCategory()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    goodsCategory () {
      goods_category_list().then(res => {
         this.categoryList = res.data.entries
      })
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleDeclaration_Cancel () {
      this.declaration_visible = false
      const form = this.$refs.goodsDeclarationModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleInventoryRecordCancel () {
      this.goods_inventory_record_visible = false
    },
    handleInventoryRecordOk () {
      this.goods_inventory_record_visible = false
    },
    handleWithGoods (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      console.log(record)
      this.$router.push({ path: '/zb/wms/goods_union/' + record.id, query: { record: Base64.encode(JSON.stringify(record)) } })
    },
    handleGoodsDeclaration (record) {
      this.$router.push({ path: '/zb/wms/goods_declaration', query: { barcode: record.barcode } })
    },
    handleEdit (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$emit('onEdit', record)
    },
    handleCountRecord (record) {
      this.goods_inventory_record = record
      this.goods_inventory_record_visible = true
    },
    handleDelet (record) {
      goods_delete(record.id).then((res) => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          if (values.shelf_life_unit === '天') {
            values.shelf_life_unit = 1
          }
          if (values.category.length !== 3) {
            this.$message.warning('所属分类必须三级')
            return
          }
          values.category = values.category[2]
          console.log('提交的数据', values)
          this.confirmLoading = true
          goods_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
          console.log(errors)
        }
      })
    },
    handleDeclarationOk () {
      const form = this.$refs.goodsDeclarationModal.form
      this.declaration_ConfirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('提交的数据', values)
          goods_create(values).then(res => {
            this.declaration_visible = false
            this.declaration_ConfirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
          }).catch((err) => {
              console.log(err)
              this.declaration_ConfirmLoading = false
            })
        } else {
          this.declaration_ConfirmLoading = false
        }
      })
    }
  }
}
</script>
